export const candidiateTheme = {
  components: {
    Toast: {
      defaultProps: {
        position: "top-right",
      },
    },
    Input: {
      variants: {
        time: {
          field: {
            // Hide the default browser time input styling
            "::-webkit-calendar-picker-indicator": {
              background: "transparent",
              bottom: 0,
              color: "transparent",
              cursor: "pointer",
              height: "auto",
              left: 0,
              position: "absolute",
              right: 0,
              top: 0,
              width: "auto",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderRadius: "md",
            borderColor: "lightGray.400",
            _hover: { borderColor: "deepBlue.500" },
            _focus: { borderColor: "deepBlue.500" },
          },
        },
      },
    },
    Form: {
      parts: ["container"],
      variants: {
        /// The default variant for FormControl
        inline: {
          container: {
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "14px",
        fontWeight: "400",
        color: "gray.700",
      },
    },
    Table: {
      baseStyle: {
        tr: {
          _last: {
            td: {
              borderBottom: "none",
            },
          },
        },
      },
      variants: {
        basic: {
          td: {
            background: "white",
            _first: {
              borderLeftRadius: "md",
            },
            _last: {
              borderRightRadius: "md",
            },
          },
        },
      },
    },
  },
  colors: {
    palegray: {
      "50": "#D9D9D9",
      "200": "#ECE8DF",
      "400": "#DBEAFE",
      "500": "#F7F5F1",
      "600": "#c4c2be",
      "800": "#94928E",
      "900": "#666461",
    },
    lightGray: {
      "100": "#F6F6F6",
      "200": "#D4D4D4",
      "400": "#BFBFBF",
    },
    darkGray: {
      "400": "#575757",
    },
    darkGreen: {
      "100": "#32C2AD26",
      "400": "#168374",
    },
    darkRed: {
      "400": "#B7171A",
    },
    brown: {
      // 15% opacity
      "100": "#DCAF5527",
      "200": "#936B1B",
    },
    lightGreen: {
      "50": "#D6FFFF",
      "100": "#A3F4DF",
      "300": "#71C1AD",
      "400": "#727E80",
      "500": "#32C2AD",
      "50033": "#32C2AD33",
      "600": "#EAF9F7",
      "700": "#40907E",
      "800": "#006252",
      "900": "#00372A",
    },
    deepBlue: {
      "50": "#F6F6F6",
      "100": "#BFBFBF",
      "200": "#7552ab",
      "300": "#46287b",
      "400": "#1E40AF",
      "500": "#15004E",
      "900": "#000027",
    },
    candidateYellow50: "#FDF2A3",
    candidateYellow: {
      "100": "#FFFFDF",
      "200": "#FCE647",
      "300": "#ffff7b",
      "500": "#FCE647",
      "700": "#c5b400",
      "800": "#c5b400",
      "900": "#c5b400",
    },
    orange: {
      "100": "#FDF0EA",
      "400": "#CF4A15",
    },
    deepPurple: {
      "100": "#E8E5ED",
      "400": "#A383CC",
    },
    fuschia: {
      "100": "#FCE7F4",
      "400": "#CC2B88",
    },
    black: {
      "400": "#232323",
    },
  },
};

export const REACT_SELECT_STYLES = {
  menuFilter: {
    menu: (baseStyles: any) => ({
      ...baseStyles,
      width: "max-content",
      minWidth: "100%",
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      position: "static",
      transform: "unset",
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: "full",
      bg: "palegray.200",
      color: "deepBlue.500",
      fontWeight: "500",
      border: "none",
      _focus: {
        bg: "white",
        fontWeight: "400",
      },
    }),
    inputContainer: (baseStyles: any) => ({
      ...baseStyles,
      position: "absolute",
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      color: "deepBlue.500",
      fontWeight: "500",
      position: "static",
      transform: "unset",
    }),
  },
  formInput: {
    container: (styles: any) => ({
      ...styles,
      bg: "white",
      borderRadius: "8px",
      height: "40px",
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: "8px",
      height: "40px",
    }),
  },
};
