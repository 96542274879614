import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

export enum SIDEBAR_STATUS {
  LINKS = "LINKS",
  SUB_MENU = "SUB_MENU",
  CLOSED = "CLOSED",
}

interface MobileSidebarContextProps {
  mobileSidebarStatus: SIDEBAR_STATUS;
  updateMobileSidebarStatus: (status: SIDEBAR_STATUS) => void;
}

const MEDIUM_BREAKPOINT = 768;

const MobileSidebarContext =
  createContext<MobileSidebarContextProps | undefined>(undefined);

export const MobileSideBarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [mobileSidebarStatus, setMobileSidebarStatus] =
    useState<SIDEBAR_STATUS>(SIDEBAR_STATUS.CLOSED);

  // Close the mobile side bar when the screen goes from the medium to large breakpoint and vice versa
  useEffect(() => {
    const handleResize = () => {
      if (
        (screenWidth < MEDIUM_BREAKPOINT &&
          window.screen.width >= MEDIUM_BREAKPOINT) ||
        (screenWidth >= MEDIUM_BREAKPOINT &&
          window.screen.width < MEDIUM_BREAKPOINT)
      ) {
        setMobileSidebarStatus(SIDEBAR_STATUS.CLOSED);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileSidebarStatus, screenWidth]);

  return (
    <MobileSidebarContext.Provider
      value={{
        mobileSidebarStatus: mobileSidebarStatus,
        updateMobileSidebarStatus: setMobileSidebarStatus,
      }}
    >
      {children}
    </MobileSidebarContext.Provider>
  );
};

export const useMobileSidebarStatus = (): MobileSidebarContextProps => {
  const context = useContext(MobileSidebarContext);
  if (!context) {
    throw new Error(
      "useMobileSidebarStatus must be used within a MobileSideBarProvider"
    );
  }

  return context;
};
