/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewPlanTemplateReadRestrictAvailabilityOption = typeof InterviewPlanTemplateReadRestrictAvailabilityOption[keyof typeof InterviewPlanTemplateReadRestrictAvailabilityOption];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewPlanTemplateReadRestrictAvailabilityOption = {
  respect_all_cfyi_events: 'respect_all_cfyi_events',
  respect_cfyi_round_events: 'respect_cfyi_round_events',
  null: null,
} as const;
