/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type EventAdminLightCandidateAttendingStatus = typeof EventAdminLightCandidateAttendingStatus[keyof typeof EventAdminLightCandidateAttendingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventAdminLightCandidateAttendingStatus = {
  needs_action: 'needs_action',
  accepted: 'accepted',
  declined: 'declined',
  tentative: 'tentative',
} as const;
