/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type OpenAIBlockCompletionContentType = typeof OpenAIBlockCompletionContentType[keyof typeof OpenAIBlockCompletionContentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpenAIBlockCompletionContentType = {
  benefits_list: 'benefits_list',
  company_summary: 'company_summary',
  role_summary: 'role_summary',
  company_values_list: 'company_values_list',
  availability: 'availability',
  email_intent_classifier: 'email_intent_classifier',
  icims_feedback_form_schema: 'icims_feedback_form_schema',
} as const;
