/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewTemplateLocationType = typeof InterviewTemplateLocationType[keyof typeof InterviewTemplateLocationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewTemplateLocationType = {
  phone: 'phone',
  google_meet: 'google_meet',
  shared_google_meet: 'shared_google_meet',
  zoom: 'zoom',
  shared_zoom: 'shared_zoom',
  skype: 'skype',
  teams: 'teams',
  shared_teams: 'shared_teams',
  no_conference: 'no_conference',
  null: null,
} as const;
